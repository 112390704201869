import React, { useReducer } from 'react'
import { graphql } from 'gatsby'
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import Seo from "../components/Seo"
import Hero from "../components/Hero"
import Accordion from '../components/Accordion'
import EventCard from "../components/EventCard"
import ReactMarkdown from 'react-markdown';
import SignUpSection from '../components/SignUpSection'

export default function Post({ data }) {

    const event = data.event.frontmatter;

    const [formInput, setFormInput] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        name: '',
        email: '',
        address: '',
        organisation: '',
        consent: 'yes'
      }
    );    

    const currentDate = new Date()
    let events = data.events.nodes
      .filter(event => new Date(event.frontmatter.event_date) >= currentDate)
      .sort(
        (a, b) =>
          new Date(a.frontmatter.event_date) - new Date(b.frontmatter.event_date)
      )

    const image = getImage(event.image);

    const handleSubmit = async(event) => {
      event.preventDefault();
      const formData = new FormData(event.target);
      const data = Object.fromEntries(formData.entries());

      try {
          document.querySelector('.rsvp__message').innerHTML = 'Submitting...';
          const response = await fetch('/.netlify/functions/sendRsvp', {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json'
              },
              body: JSON.stringify(data)
          });

          if (response.ok) {
            document.getElementById('rsvpForm').reset();
            // add thank you message in rsvp__message div
            document.querySelector('.rsvp__message').innerHTML = 'Thank you for submitting your RSVP.';
            document.querySelector('.rsvp__message').classList.add('alert', 'alert-success');
          } else {
            document.querySelector('.rsvp__message').innerHTML = 'Failed to submit RSVP. Please, try again.';
            document.querySelector('.rsvp__message').classList.add('alert', 'alert-danger');
          }
      } catch (error) {
          console.error('Error submitting RSVP:', error);
          alert('An error occurred while submitting your RSVP.');
      }
    }

    const handleChange = ( event ) => {
      const name = event.target.name;
      const newValue = event.target.value;
      setFormInput( { [name]: newValue } );
    }

    return (
      <>
        <Seo title={`Event: ${ event.event_name }`} />

        <Hero headline="Events" size="small" page="event">
          <StaticImage 
            className="hero__picture"
            src="../../static/media/about-hero.jpg" 
            alt=""
            loading="eager"
          />
        </Hero>

        <section className="container">
          
          <article className="post event">

            <div className="row title-wrapper">
              
              <div className="col-md-8">
              <div className='event__meta'>
                <div className='event__date'>{new Date(event.event_date).toLocaleDateString('en-US', { weekday: 'long', day: 'numeric', month: 'long', year: 'numeric' })}</div>
                <div className='event__time'>{event.event_time_from} to {event.event_time_to}</div>
                <div className='event__place'>{event.event_place}</div>
              </div>
              <h1 className="post__title">{ event.event_name }</h1>
              { event.button_link && event.button_text &&
                <a href={ event.button_link } className="button button--primary" target='_blank'>{ event.button_text }</a>
              }
              <hr />
              </div>

              <div className="col-md-4">
              <GatsbyImage
                image={image}
                alt=""
                className="figure__image"
              />
              <hr className='d-block d-md-none' />
              </div>

            </div>

            <div className="row content-wrapper">
              <div className="col-md-8 event__content">
              <ReactMarkdown>{event.event_description}</ReactMarkdown>
              </div>
              { event.accordion_content && 
              <div className='col-md-8 mt-3'>
              { event.accordion_content.map( item => 
                <Accordion 
                question={item.headline} 
                answer={item.content}
                />
              )}
              </div>
              }
              { event.allow_rsvp &&
              <div className="col-md-8">
                <div className='event__form'>
                  <h2 className='mb-md-4'>{event.rsvp_form_title}</h2>
                  <form id="rsvpForm" onSubmit={(e) => handleSubmit(e) } action="" className='form--signup'>
                    <div className='row'>
                      <div className='col-md-5'>
                        <div class="input-wrapper">
                          <input onChange={ (e) => handleChange(e) } type="text" placeholder="Name" id="name" name="name" required />
                          <span className={`input__custom-label ${ formInput.name ? 'show' : '' }`}>Name</span>
                        </div>
                      </div>
                      <div className='col-md-5'>
                        <div class="input-wrapper">
                          <input onChange={ (e) => handleChange(e) } type="email" placeholder="E-mail" id="email" name="email" required />
                          <span className={`input__custom-label ${ formInput.email ? 'show' : '' }`}>Email</span>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-5'>
                        <div class="input-wrapper">
                          <input onChange={ (e) => handleChange(e) } type="text" placeholder="Post code" id="address" name="address" />
                          <span className={`input__custom-label ${ formInput.address ? 'show' : '' }`}>Post code</span>
                        </div>
                      </div>
                      <div className='col-md-5'>
                        <div class="input-wrapper">
                          <input onChange={ (e) => handleChange(e) } type="text" placeholder="Organisation" id="organisation" name="organisation" />
                          <span className={`input__custom-label ${ formInput.organisation ? 'show' : '' }`}>Organisation</span>
                        </div>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-5'>
                        <button type="submit" className='button button--primary'>{event.rsvp_button_label}</button>
                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-10'>
                        <div className="form__consent mt-2">

                          <div className="consent__text">
                            <p>The City of London Corporation will only use the information you give us for electoral purposes. <a href="/privacy-policy">Please see our privacy statement for more information.</a></p>
                          </div>

                          <div className="consent__inputs">

                            <div className="radio-wrapper">
                              <input onChange={ (e) => handleChange(e) } id="yes" name="consent" type="radio" value="yes" checked = { formInput.consent === 'yes' ? true : false} />
                              <label htmlFor="yes">Yes</label>
                            </div>

                            <div className="radio-wrapper">
                              <input onChange={ (e) => handleChange(e) } id="no" name="consent" type="radio" value="no" checked = { formInput.consent === 'no' ? true : false} />
                              <label htmlFor="no">No</label>
                            </div>
                            
                          </div>  

                        </div>
                      </div>
                    </div>

                    <div className="rsvp__message"></div>
                    <input type="hidden" id="spreadsheetId" name="spreadsheetId" value={event.spreadheet_id}/>
                  </form>
                </div>
              </div>
              }
            </div>

          </article>

        </section>

        { events.length > 0 && 
        <section className="other-events">

          <div className="container">
          <h4 className='mb-md-5'>Other Events</h4>
          <div className="row">
            { events.map( event => 
            <div className="col-md-4">
              <EventCard
              eventName={event.frontmatter.event_name}
              eventDate={event.frontmatter.event_date}
              eventTimeFrom={event.frontmatter.event_time_from}
              eventTimeTo={event.frontmatter.event_time_to}
              eventPlace={event.frontmatter.event_place}
              eventSlug={event.frontmatter.event_slug}
              eventId={event.id}
              />
            </div>
            ) 
            }
          </div>
          <div className="row">
            <div className="col-md-12">
            <p className="text-center mt-2 mt-md-5">
              <a href="/events" className="btn-all">See all events</a>
            </p>
            </div>
          </div>
          </div>

        </section>
        }

        <SignUpSection />
      </>
    )

}

export const query = graphql`
  query Event($id: String!) {
    events: allMarkdownRemark (filter: {frontmatter: {type: {eq: "event"}}, id: { ne: $id }}, limit: 3) {
      nodes {
        id
        frontmatter {
          event_name
          event_slug
          event_date
          event_time_from
          event_time_to
          event_place
        }
      }
    }
    event: markdownRemark(id: {eq: $id}) {
      html
      frontmatter {
        event_name
        event_slug
        event_date
        event_time_from
        event_time_to
        event_place
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        event_description
        button_link
        button_text
        allow_rsvp
        spreadheet_id
        rsvp_form_title
        rsvp_button_label
        accordion_content {
          headline
          content
        }
      }
    }
  }
`;